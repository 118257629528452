// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

$enable-negative-margins: true;

// 2. Include any default variable overrides here
$primary: #0056bd;
$input-focus-border-color: #86b7fe;
:root {
  --comment-background: #f7fafe;
  --ffx-overlay-darkblue: #00152e;
}
// Accordion
$accordion-button-active-bg: transparent;
$accordion-border-color: transparent;

// $border-color: #ced4da;

//$input-btn-focus-box-shadow: 0px 0px 0px 4px rgba(13, 110, 253, 0.25);
//$input-disabled-border-color: #ced4da;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

$border-color: $gray-400; //#ced4da
$input-disabled-bg: $gray-100; //#f8f9fa

// 4. Include any default map overrides here
$custom-spacers: (
  45: $spacer * 2.5,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8,
);

$spacers: map-merge($spacers, $custom-spacers);

$custom-font-sizes: (
  7: $font-size-base * 0.875,
  8: $font-size-base * 0.75,
);

$font-sizes: map-merge($font-sizes, $custom-font-sizes);

//Create your own map
// $custom-colors: (
// );

//  $override-colors : (
//      "primary": $primary
//  );

// Merge the maps
// $theme-colors: map-merge($theme-colors, $override-colors);
//$theme-colors: map-merge($theme-colors, $custom-colors);

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed,
    ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "width":
      map-merge(
        map-get($utilities, "width"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "width"), "values"),
              (
                10: 10%,
                15: 15%,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "border":
      map-merge(
        map-get($utilities, "border"),
        (
          responsive: true,
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    "rounded":
      map-merge(
        map-get($utilities, "rounded"),
        (
          responsive: true,
        )
      ),
  )
);

@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/modal";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here

body {
  font-family: "Figtree", serif;
}

.was-validated .invalid-feedback {
  &:has(+ :invalid) {
    display: block;
  }
}

.invalid-feedback {
  &:has(+ .is-invalid) {
    display: block;
  }
}

ff-control-validation .invalid-feedback {
  display: block;
}

.invalid-p-focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

$alert-danger-subtle: #fdf3f4;
$alert-danger: #f5c2c7;
.validation-summary {
  border-color: $alert-danger !important;
  background-color: $alert-danger-subtle;

  .validation-message {
    color: #842029;
  }
}

// 9. Responsive classes

@include media-breakpoint-up(lg) {
  .responsive-lg-shadow {
    box-shadow: $box-shadow;
  }
}

@include media-breakpoint-down(lg) {
  .stepper-max-width-58 {
    max-width: 58px;
  }

  .form-p-left-58 {
    padding-left: 58px !important;
  }
}

@import "_buttons";
@import "_tooltip";
@import "_primeNg";
@import "_scrollbar";
@import "_commentsPopover";
@import "_alerts";
@import "_questions";
//input {
//background-color: $input-focus-border-color !important;
//background-color: $input-btn-focus-box-shadow !important;
// background-color: $input-disabled-bg;
//}

// GR refactor this class
$--bs-validation-border-subtle: $red;

//@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

@import "primeng/resources/themes/bootstrap4-light-blue/theme.css";
@import "primeng/resources/primeng.css";
