$alert-divider-information: #f8fbff;
$alert-divider-information-border: #b6d4fe;
.alert-divider-information {
  background-color: $alert-divider-information !important;
  border-color: $alert-divider-information-border !important;
}

.alert-divider-danger {
  background-color: $alert-danger-subtle !important;
  border-color: $alert-danger !important;
}

$alert-divider-warning: #fffcf0;
$alert-divider-warning-border: #ffecb5;
.alert-divider-warning {
  background-color: $alert-divider-warning !important;
  border-color: $alert-divider-warning-border !important;
}

$alert-divider-success: #f2f8f5;
$alert-divider-success-border: #badbcc;
.alert-divider-success {
  background-color: $alert-divider-success !important;
  border-color: $alert-divider-success-border !important;
}

$alert-divider-history: #f1fcff;
$alert-divider-history-border: #b6effb;
.alert-divider-history {
  background-color: $alert-divider-history !important;
  border-color: $alert-divider-history-border !important;
}
