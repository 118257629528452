.btn-primary {
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-active-bg: #0b5ed7;
  --bs-btn-active-border-color: #0a58ca;

  &:active {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
}

.btn-outline-primary {
  // btn-outline-primary is the bs variant for our secondary type
  &:active {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
}

.btn-outline-danger {
  // btn-outline-danger is the bs variant for our danger/delete type
  &:active {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
}
