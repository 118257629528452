.p-multiselect {
  min-height: 35px;
  @extend .form-control, .p-0;
}

.p-multiselect .p-multiselect-label {
  @extend .d-flex, .flex-wrap;
}

.p-element.ng-dirty.ng-invalid {
  .p-checkbox .p-checkbox-box.p-focus,
  .p-radiobutton .p-radiobutton-box.p-focus,
  .p-autocomplete .p-autocomplete-multiple-container.p-focus,
  .p-multiselect.p-focus {
    @extend .invalid-p-focus;
  }
}

.p-multiselect .p-element .p-overlay.p-component {
  @extend .top-100;
}

.p-component {
  font-family: inherit;
}

.p-multiselect-trigger .p-icon-wrapper {
  color: var(--bs-gray-800);
  width: 12px;
}

.p-multiselect-label-container {
  @extend .d-inline-flex, .p-1;
}

.p-multiselect-chip .p-multiselect-label-container .p-multiselect-label {
  @extend .d-flex, .p-0, .column-gap-1;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  @extend .px-2, .py-1;
}

.p-autocomplete-multiple-container {
  @extend .d-flex, .form-control;
}

.p-autocomplete {
  height: 35px;
  @extend .d-inline-flex, .w-100;
}

.p-autocomplete-token {
  @extend .px-2, .py-0, .column-gap-2, .text-black;
}

.p-autocomplete-input-token {
  @extend .px-2, .py-0;
}

.p-autocomplete
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  @extend .d-flex, .m-0;
}

p-radiobutton.ng-dirty.ng-invalid {
  .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: var(--bs-red);
  }
}
